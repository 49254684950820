<template>
  <div
    class="row"
  >
    <div class="col-12 col-xl-8 col-xxl-6">
      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Produktgruppe
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <basic-input
                v-model="rate.product.hn"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="HN / Produktgruppe"
                :disabled="productsLoading"
                placeholder="HN"
                :margin="false"
                required
              />
            </div>
            <div class="col-12 col-md-4">
              <basic-input
                v-model="rate.product.name"
                :disabled="productsLoading"
                :margin="false"
                placeholder="Produktgruppe"
                name="Produktgruppe"
                required
              />
            </div>
            <div class="col-12">
              <basic-select
                v-model="selectedProduct"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                :disabled="productsLoading"
                :horizontal="true"
                class="mb-0"
                label="Alternativprodukte zu"
                :small="`Wenn ein Tarif eines Produktes in der Liste verfügbar ist, sind die Tarife dieser Produktgruppe ${rate.product.hn ? '('+ rate.product.hn + ') ' : ''}verborgen`"
              >
                <template v-for="(rateproduct, index) in products">
                  <option
                    :key="index"
                    :value="rateproduct.hn"
                  >
                    <span>{{ rateproduct.name }} ({{ rateproduct.hn }})</span>
                  </option>
                </template>
                <template #append>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="addConflictingProduct()"
                  >
                    <i class="fas fa-plus" />
                  </button>
                </template>
              </basic-select>
              <div
                class="form-row justify-content-end"
              >
                <div class="col-12 col-lg-8">
                  <div v-if="productsLoading">
                    Lade Produkte...
                  </div>
                  <transition-group
                    v-else
                    name="products"
                    tag="ul"
                    class="list-group list-group-flush"
                  >
                    <li
                      v-for="(hn, index) in rate.product.conflictingProducts"
                      :key="'conflicting'+index"
                      class="list-group-item"
                    >
                      <div
                        class="form-row align-items-center"
                      >
                        <div class="col">
                          {{ hn }} - {{ getProductByHn(hn).name }}
                        </div>
                        <div class="col-auto">
                          <button
                            class="btn btn-link p-0"
                            @click="removeConflictingProduct(index)"
                          >
                            <i class="fas fa-trash text-danger" />
                          </button>
                        </div>
                      </div>
                    </li>
                  </transition-group>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ca-card>

      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Stammdaten
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <basic-input
            v-model="rate.un"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="UN"
            placeholder="UN"
            required
          />

          <basic-input
            v-model="rate.name"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Tarif"
            placeholder="Tarif"
            required
          />
          <basic-select
            v-model="rate.laufzeit"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Laufzeit"
            placeholder="bitte wählen"
            required
            :options="laufzeitOptions"
          />
        </template>
      </ca-card>

      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Zusatz
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <basic-typeahead
            id="agb-search"
            v-model="agbSearch"
            placeholder="Suche nach einer Garantiebedingung"
            label="GB"
            required
            :query="searchAgbByName"
            :input-formatter="agbFormatter"
            :result-formatter="agbFormatter"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            @hit="rate.agbId = $event._id"
          />

          <basic-select
            v-model="rate.rateType"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Tarif"
            required
            :options="[
              {label: 'Händler Tarif', value: 'HAENDLER'},
              {label: 'Mobilitäts Tarif', value: 'MOBILITY'},
              {label: 'Taxi Tarif', value: 'TAXI'},
              {label: 'RepKo Tarif', value: 'REPKO'},
              {label: 'GKV Tarif', value: 'GKV'},
            ]"
          />

          <basic-select
            v-model="rate.GVCfrequency"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="GVC Häufigkeit"
            required
          >
            <option value="0">
              Kein GVC
            </option>
            <option value="6">
              6 Monate
            </option>
            <option value="12">
              12 Monate
            </option>
          </basic-select>

          <basic-input
            v-model="rate.maxLaufzeit"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Maximal verlängerbare Laufzeit"
            placeholder="Maximal verlängerbare Laufzeit"
            :required="rate.extendRateIds && rate.extendRateIds.length > 0"
          >
            <template #append>
              <span class="input-group-text">Monat(e)</span>
            </template>
          </basic-input>

          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <basic-select
                v-model="selectedProductHn"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="Verlängerungen"
                placeholder="Auswahl 1"
                :margin="false"
                :options="productOptions"
              />
            </div>
            <div class="col-12 col-md-4">
              <basic-select
                v-model="selectedRateId"
                :margin="false"
                placeholder="Auswahl 2"
                :options="rateOptions"
              >
                <template #append>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="addExtendRate"
                  >
                    <i class="fa fas fa-plus" />
                  </button>
                </template>
              </basic-select>
            </div>
          </div>
          <div class="form-row justify-content-end">
            <div class="col-12 col-lg-8">
              <transition-group
                name="products"
                tag="ul"
                class="list-group list-group-flush"
              >
                <li
                  v-for="(extendRate, index) in rate.extendRates"
                  :key="extendRate._id"
                  class="list-group-item"
                >
                  <div
                    class="form-row align-items-center"
                  >
                    <div class="col">
                      <router-link :to="{name: 'RateEdit', params: {rateId: extendRate._id}}">
                        ({{ extendRate.product.hn }}-{{ extendRate.un }}) {{ extendRate.name }}
                      </router-link>
                    </div>
                    <div class="col-auto">
                      <button
                        class="btn btn-link p-0"
                        type="button"
                        @click="removeExtendRate(index)"
                      >
                        <i class="fas fa-trash text-danger" />
                      </button>
                    </div>
                  </div>
                </li>
              </transition-group>
            </div>
          </div>
        </template>
      </ca-card>

      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Eigenschaften
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <basic-input
                v-model="rate.kwVon"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="kW Von / kW Bis"
                name="kW Von"
                placeholder="kW Von"
                :margin="false"
                required
              />
            </div>
            <div class="col-12 col-md-4">
              <basic-input
                v-model="rate.kwBis"
                :margin="false"
                placeholder="kW Bis"
                name="kW Bis"
                required
              />
            </div>
          </div>

          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <basic-input
                v-model="rate.kmVon"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="kmVon / kmBis"
                name="kmVon"
                placeholder="kmVon"
                :margin="false"
                required
              />
            </div>
            <div class="col-12 col-md-4">
              <basic-input
                v-model="rate.kmBis"
                :margin="false"
                name="kmBis"
                placeholder="kmBis"
                required
              />
            </div>
          </div>

          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <basic-input
                v-model="rate.alterVon"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="alterVon / alterBis"
                name="alterVon"
                placeholder="alterVon"
                :margin="false"
                required
              />
            </div>
            <div class="col-12 col-md-4">
              <basic-input
                v-model="rate.alterBis"
                :margin="false"
                name="alterBis"
                placeholder="alterBis"
                required
              />
            </div>
          </div>

          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <basic-input
                v-model="rate.preisVon"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="preisVon / preisBis"
                name="preisVon"
                placeholder="preisVon"
                :margin="false"
                required
              />
            </div>
            <div class="col-12 col-md-4">
              <basic-input
                v-model="rate.preisBis"
                :margin="false"
                name="preisBis"
                placeholder="preisBis"
                required
              />
            </div>
          </div>
        </template>
      </ca-card>

      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Kalkulation
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <BasicCurrencyInput
                v-model="rate.netto"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                name="Netto"
                label="Netto / Brutto"
                placeholder="Netto"
                :margin="false"
                required
              />
            </div>
            <div class="col-12 col-md-4">
              <BasicCurrencyInput
                v-model="rate.brutto"
                :margin="false"
                name="Brutto"
                placeholder="Brutto"
                required
              />
            </div>
          </div>

          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <BasicCurrencyInput
                v-model="rate.vst"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="Vst / Risiko"
                name="Vst"
                placeholder="Vst"
                :margin="false"
                required
              />
            </div>
            <div class="col-12 col-md-4">
              <BasicCurrencyInput
                v-model="rate.risiko"
                :margin="false"
                name="Risiko"
                placeholder="Risiko"
                required
              />
            </div>
          </div>

          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <basic-input
                v-model="rate.versicherer"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                name="Versicherer"
                label="Versicherer / MobileGarantie"
                placeholder="Versicherer"
                :margin="false"
                required
              />
            </div>
            <div class="col-12 col-md-4">
              <BasicCurrencyInput
                v-model="rate.mobileG"
                :margin="false"
                name="MobileGarantie"
                placeholder="MobileGarantie"
                required
              />
            </div>
          </div>

          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <BasicCurrencyInput
                v-model="rate.prov5"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                name="Provision 5"
                label="Provision 5 / Provision 10"
                placeholder="Provision 5"
                :margin="false"
                required
              />
            </div>
            <div class="col-12 col-md-4">
              <BasicCurrencyInput
                v-model="rate.prov10"
                :margin="false"
                name="Provision 10"
                placeholder="Provision 10"
                required
              />
            </div>
          </div>

          <div class="form-row my-3">
            <div class="col-12 col-md-8">
              <BasicCurrencyInput
                v-model="rate.prov25"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="Provision 25"
                placeholder="Provision 25"
                :margin="false"
                required
              />
            </div>
          </div>
        </template>
      </ca-card>

      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Sichtbarkeit
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <button
            type="button"
            class="btn"
            :class="{'btn-outline-success' : !rate.deactivated, 'btn-outline-danger' : rate.deactivated}"
            @click="$set(rate, 'deactivated', !rate.deactivated)"
          >
            <span v-if="!rate.deactivated">Aktiv</span>
            <span v-else>Deaktiviert</span>
          </button>
        </template>
      </ca-card>
    </div>
  </div>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicCurrencyInput from '@/components/BaseComponents/BasicCurrencyInput'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead'
import CaCard from '@/components/Card'
import feathers from '@/api'
export default {
  components: {
    BasicInput,
    BasicSelect,
    BasicCurrencyInput,
    BasicTypeahead,
    CaCard
  },
  props: {
    rate: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    agbSearch: '',
    rates: [],
    selectedProductHn: null,
    selectedRateId: null,
    selectedProduct: null,
    laufzeitOptions: [
      { label: '1 Monate', value: 1 },
      { label: '2 Monate', value: 2 },
      { label: '3 Monate', value: 3 },
      { label: '4 Monate', value: 4 },
      { label: '5 Monate', value: 5 },
      { label: '6 Monate', value: 6 },
      { label: '7 Monate', value: 7 },
      { label: '8 Monate', value: 8 },
      { label: '9 Monate', value: 9 },
      { label: '10 Monate', value: 10 },
      { label: '11 Monate', value: 11 },
      { label: '12 Monate', value: 12 },
      { label: '24 Monate', value: 24 },
      { label: '36 Monate', value: 36 },
      { label: '48 Monate', value: 48 }
    ],
    productsLoading: true,
    ratesLoading: true
  }),
  computed: {
    productOptions () {
      return this.rates
        .reduce((products, rate) => {
          if (products.every(product => product.hn !== rate.product.hn)) {
            products.push(rate.product)
          }
          return products
        }, [])
        .map(product => ({
          label: `(${product.hn}) ${product.name}`,
          value: product.hn,
          id: product._id
        }))
    },
    rateOptions () {
      return this.rates
        .filter(rate => rate.product.hn === this.selectedProductHn)
        .map(rate => ({
          label: `(${rate.un}) ${rate.name}`,
          value: rate._id
        }))
    },
    products () {
      return this.$store.getters['products/list'] || []
    }
  },
  watch: {
    'rate.product.hn': 'findProduct'
  },
  async created () {
    if (!this.rate.product.hn) this.$set(this.rate.product, 'hn', '')
    if (!this.rate.product.name) this.$set(this.rate.product, 'name', '')
    if (!this.rate.product.conflictingProducts) this.$set(this.rate.product, 'conflictingProducts', [])
    if (this.rate && this.rate.agb && this.rate.agb.name) this.agbSearch = this.rate.agb.name
    await this.findProducts()
    await this.findRates()
  },
  methods: {
    async searchAgbByName (name) {
      return this.$store.dispatch('agbs/find', {
        query: {
          $limit: 10,
          name: { $regex: '^' + name, $options: 'i' }
        }
      })
    },
    agbFormatter (agb) {
      return `${agb.name}`
    },
    async findRates () {
      this.ratesLoading = true
      const rates = await feathers.service('rates').find({
        query: {
          $sort: { createdAt: 1 },
          $client: {
            paginate: false
          },
          $select: ['product.hn', 'productId', 'un', 'name']
        }
      })
      this.rates = rates
      this.ratesLoading = false
    },
    findProduct (hn) {
      if (this.products.length === 0) return
      const product = this.getProductByHn(hn)
      if (product) {
        // this.rate.product = {
        //   ...product
        // }
        this.$set(this.rate.product, 'hn', product.hn)
        this.$set(this.rate.product, 'name', product.name)
        this.$set(this.rate.product, 'conflictingProducts', product.conflictingProducts || [])
        this.$set(this.rate.product, '_id', product._id)
      } else {
        this.$set(this.rate.product, 'hn', hn)
        this.$set(this.rate.product, 'name', '')
        this.$set(this.rate.product, 'conflictingProducts', [])
        this.$set(this.rate.product, '_id', null)
      }
    },
    async findProducts () {
      this.productsLoading = true
      await this.$store.dispatch('products/find', {
        query: {
          $client: { paginate: false }
        }
      })
      this.productsLoading = false
    },
    addConflictingProduct () {
      const productAlreadyAdded = this.rate.product.conflictingProducts.includes(this.selectedProduct.toString())
      if (!productAlreadyAdded) {
        this.rate.product.conflictingProducts.push(this.selectedProduct.toString())
      }
      this.selectedProduct = null
    },
    getProductByHn (hn) {
      const product = this.products.find(product => product.hn.toString() === hn.toString())
      return product
    },
    removeConflictingProduct (index) {
      this.rate.product.conflictingProducts.splice(index, 1)
    },
    addExtendRate () {
      if (!this.selectedRateId) return
      const selectedRate = this.rates.find(rate => rate._id === this.selectedRateId)
      this.rate.extendRates.push(selectedRate)
      this.rate.extendRateIds.push(this.selectedRateId)
      this.selectedProductHn = null
      this.selectedRateId = null
    },
    removeExtendRate (index) {
      this.rate.extendRates.splice(index, 1)
      this.rate.extendRateIds.splice(index, 1)
    }
  }
}
</script>

<style>

</style>
